<template>
  <div class="map">
    <div class="main-menu">
      <main class="main">
        <div class="container-map">
          <div class="container-road">
            <h1>
              <img
                :src="
                  require('./img/main-menu/menu_ttl' +
                    (map.grade ? map.grade : '') +
                    '.png')
                "
                alt="サンプルタイトル"
              />
            </h1>
            <nav class="btn-back" @click.stop="back('トップ[画面:がめん]')">
              <a href="#"><span class="sp-none">もどる</span></a>
            </nav>
            <ul class="wrap">
              <li
                v-if="map.news > 0"
                key="menu-news"
                class="news"
                @click="click_news()"
              >
                <a href="#"><span>ニュース</span></a>
              </li>
              <li
                v-if="enq"
                key="menu-enq"
                class="questionnaire"
                @click="click_enq()"
              >
                <a href="#"><span>アンケート</span></a>
              </li>

              <li
                v-for="(stage, id) in map.stages"
                :key="'stage' + id"
                :class="'house0' + (id + 1)"
                @click="click(stage)"
              >
                <a
                  href="#"
                  :class="stage.isActive ? null : 'disabled'"
                  :tabindex="stage.isActive ? null : -1"
                  ><span v-html="stage.name"></span
                ></a>
              </li>

              <li key="menu-help" class="help" @click="click_help()">
                <a href="#"><span>ヘルプ</span></a>
              </li>
              <li key="menu-logout" class="Logout" @click.stop="logout">
                <a href="#"><span>ログアウト</span></a>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { formatAllasLine } from '@/service/formatter.js';
import Dialog from '@/components/Dialog.vue';
import DialogRooms from './components/DialogRooms.vue';
import DialogTwitter from '@/components/DialogTwitter.vue';
import DialogScrollText from '@/components/DialogScrollText.vue';
import dialogs from '@/mixins/dialogs';
import back from '@/mixins/back';
import { LITERACY_MAP_HELP } from '@/service/help.js';
export default {
  name: 'Map',
  mixins: [dialogs, back],
  data() {
    return {
      status_anchors: [
        { text: '<ruby>体験<rt>たいけん</rt></ruby>' },
        { text: '<ruby>対応<rt>たいおう</rt></ruby>' },
        { text: '<ruby>検定<rt>けんてい</rt></ruby>' }
      ],
      map: [],
      enq: null
    };
  },
  async mounted() {
    await this.init();
    this.$emit('load', 'main-menu');
  },
  methods: {
    async click(stage) {
      //console.log(stage);
      if (stage.isActive) {
        const result = await this.$confirm({
          component: DialogRooms,
          props: {
            title: stage.title,
            rooms: stage.rooms
          }
        });
        //console.log(result);
        this.$router
          .push({ name: 'LiteracyRoom', params: result })
          .catch(() => {});
      }
    },
    async click_help() {
      await this.$confirm({
        component: DialogScrollText,
        props: {
          texts: LITERACY_MAP_HELP
        }
      });
    },
    async click_news() {
      await this.$confirm({
        component: DialogTwitter,
        props: {
          twitter_id: 'ZwoPE2P39ckfYv9'
        }
      });
    },
    async click_enq() {
      const result = await this.$confirm({
        component: Dialog,
        props: {
          type: 'vertical',
          title: 'アンケートのお願いです',
          options: this.enq
        }
      });
      if (result) {
        window.open(result.url, '_blank');
        await this.$store.dispatch('api/setEnquete', result._id);
        await this.init();
      }
    },
    moveToGame(room_id, status) {
      this.$router
        .push({ name: 'LiteracyRoom', params: { id: room_id, status: status } })
        .catch(() => {});
    },
    async logout() {
      const result = await this.$confirm({
        component: Dialog,
        props: {
          title: 'ログアウトの確認',
          message: 'ログアウトしますか？',
          options: [
            { text: 'はい', value: true },
            { text: 'いいえ', value: false }
          ]
        }
      });
      if (result) {
        this.$store.commit('logout');
        this.$router.push({ path: '/' });
      }
    },
    async init() {
      this.$tryLoadingAsync(async () => {
        const map = await this.$store.dispatch('api/getLiteracy');
        for (let stage_id = map.stages.length; stage_id < 5; stage_id++) {
          map.stages.push({ name: '', rooms: {} });
        }
        for (let stage_id in map.stages) {
          const stage = map.stages[stage_id];
          stage.isActive = stage_id <= map.map && stage.name;
          stage.name = formatAllasLine.format(stage.name);
          for (let id in stage.rooms) {
            const room = stage.rooms[id];
            room.name = formatAllasLine.format(room.name);
            room.default = room.status % 4;
            if (room.default === 3) {
              room.default = 2;
            }
            if (room.status <= 0) {
              room.isNew = true;
            }
            if (room.status >= 4) {
              room.isClear = true;
            }
            room.id = id;
            room.value = true;
          }
        }
        this.map = map;
        this.enq = null;
        if (map.enquetes && map.enquetes.length > 0) {
          this.enq = [];
          for (let enq of map.enquetes) {
            let text = '<p>' + enq.name + '</p>';
            const deadline = new Date(enq.deadline);
            if (deadline.getTime() > 0) {
              text +=
                '<p class="small">' + deadline.toLocaleString() + 'まで</p>';
            } else {
              text += '<p class="small">いつでもOK</p>';
            }
            this.enq.push({
              text: text,
              value: enq
            });
          }
          this.enq.push({ text: 'もどる', value: null });
        }
        console.log('map', this.map);
      });
    }
  }
};
</script>

<style scoped></style>
