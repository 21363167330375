<template>
  <Modal class="dialog-rooms" @click="close">
    <div class="literacy_menu">
      <ul class="menu-list menu-normal">
        <li
          v-for="(room, room_id) in rooms"
          :key="room_id"
          :class="[room.isNew ? 'new' : null, room.isClear ? 'clear' : null]"
          :style="{ order: room.index }"
        >
          <div class="quest">
            <div class="title">
              <h2>
                <a href="#" @click="select(room)" v-html="room.name"></a>
              </h2>
            </div>
            <ul class="state literacy_menu_state">
              <li
                v-for="(anchor, anchor_id) in status_anchors"
                :key="room_id + anchor_id"
                :class="room.default === anchor_id ? 'active' : null"
              >
                <a
                  v-if="room.default >= anchor_id"
                  href="#"
                  @click.prevent="select(room, anchor_id)"
                  ><span v-html="anchor.text"></span
                ></a>
                <span v-else><span v-html="anchor.text"></span></span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </Modal>
</template>

<script>
import modal from '@/mixins/modal';
export default {
  name: 'Dialog',
  components: {
    Modal: () => import('@/components/Modal.vue')
  },
  mixins: [modal],
  props: {
    type: { type: String, default: 'horizontal' },
    title: { type: String },
    message: { type: String },
    image: String,
    rooms: {
      type: Object,
      default: () => {
        return { '': { id: '', name: '', status: 0, index: 0, value: true } };
      }
    },
    onClose: { type: Function, required: true }
  },
  data() {
    return {
      visible: false,
      result: false,
      status_anchors: [
        { text: '<ruby>体験<rt>たいけん</rt></ruby>' },
        { text: '<ruby>対応<rt>たいおう</rt></ruby>' },
        { text: '<ruby>検定<rt>けんてい</rt></ruby>' }
      ]
    };
  },
  methods: {
    select(room, anchor_id) {
      this.onClose({ id: room.id, status: anchor_id });
      this.close();
    }
  }
};
</script>

<style scoped src="../css/modal.css"></style>
<style scoped>
/*
a {
  text-decoration: none;
}
.literacy_menu {
  background-image: url(../img/main-menu/bg-main-menu-lp.png);
  background-position: top 0 center;
  background-repeat: repeat-y;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  border-radius: 1em;
  padding: 1em 0.5em;
}

.menu-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  overflow: auto;
}
.menu-list > li {
  position: relative;
  padding: 25px 150px;
}
@media screen and (max-width: 770px) {
  .menu-list > li {
    padding: 25px 30px;
  }
}
.menu-list > li::before,
.menu-list > li::after {
  display: block;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  content: '';
}
.menu-list > li > .quest {
  position: relative;
  margin: 0 auto;
  padding: 36px;
  width: auto;
  border-radius: 12px;
  background-color: #ffffff;
}
@media screen and (max-width: 770px) {
  .menu-list > li > .quest {
    padding: 28px 14px;
  }
}
.menu-list > li > .quest .title h2 {
  margin-bottom: 0;
  font-size: 2em;
}
@media screen and (max-width: 770px) {
  .menu-list > li > .quest .title h2 {
    font-size: 1.4em;
  }
}
.menu-list > li > .quest .title h2 > a {
  color: inherit;
}
.menu-list > li > .quest .state {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.menu-list > li > .quest .state > li > a,
.menu-list > li > .quest .state > li > span {
  display: block;
  box-sizing: border-box;
  margin-top: 10px;
  margin-right: 7px;
  padding-right: 16px;
  width: 4em;
  height: 2em;
  border-radius: 24px;
  background-color: #ffb357;
  background-image: url(../img/common/icon-arrow-white.svg);
  background-size: auto 50%;
  background-position: right 8px center;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
  line-height: 1.5em;
}
@media screen and (max-width: 770px) {
  .menu-list > li > .quest .state > li > a,
  .menu-list > li > .quest .state > li > span {
    margin-top: 8px;
    margin-right: 5px;
    padding-right: 8px;
    border-radius: 15px;
    background-position: right 5px center;
    font-size: 1.6em;
  }
}
@media screen and (max-width: 351px) {
  .menu-list > li > .quest .state > li > a,
  .menu-list > li > .quest .state > li > span {
    width: 68px;
  }
}
.menu-list > li > .quest .state > li.active > a {
  background-color: #db4807;
}
.menu-list > li > .quest .state > li.active ~ * > span {
  background-color: #ffd6a4;
}
.menu-list > li.new > .quest::after {
  display: block;
  position: absolute;
  top: -25px;
  left: -35px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: #d60000;
  background-image: url(../img/main-menu/icon-new.svg);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 0;
  font-weight: 700;
  text-align: center;
  line-height: 60px;
  content: 'NEW';
}
@media screen and (max-width: 770px) {
  .menu-list > li.new > .quest::after {
    top: -20px;
    left: -20px;
    width: 38px;
    height: 38px;
    border-radius: 19px;
    line-height: 38px;
  }
}
.menu-list > li.clear > .quest {
  background-image: url(../img/main-menu/bg-clear.svg);
  background-size: 158px auto;
  background-position: bottom 12px right 20px;
  background-repeat: no-repeat;
}
@media screen and (max-width: 770px) {
  .menu-list > li.clear > .quest {
    background-size: 100px auto;
    background-position: bottom 3px right -20px;
  }
}

.menu-special > li:nth-child(odd)::before {
  bottom: -25px;
  left: 30px;
  width: 226px;
  height: 188px;
  background-image: url(../img/main-menu/img-special-01.svg);
}
@media screen and (max-width: 770px) {
  .menu-special > li:nth-child(odd)::before {
    bottom: -15px;
    left: -20px;
    width: 113px;
    height: 94px;
  }
}
.menu-special > li:nth-child(odd)::after {
  right: 30px;
  bottom: 10px;
  width: 143px;
  height: 116px;
  background-image: url(../img/main-menu/img-tree-right.svg);
}
@media screen and (max-width: 770px) {
  .menu-special > li:nth-child(odd)::after {
    right: 5px;
    bottom: 0;
    width: 71.5px;
    height: 58px;
  }
}
.menu-special > li:nth-child(even)::before {
  right: 20px;
  bottom: -10px;
  width: 230px;
  height: 112px;
  background-image: url(../img/main-menu/img-special-02.svg);
}
@media screen and (max-width: 770px) {
  .menu-special > li:nth-child(even)::before {
    right: 5px;
    bottom: 0;
    width: 115px;
    height: 56px;
  }
}
.menu-special > li:nth-child(even)::after {
  bottom: 10px;
  left: 30px;
  width: 143px;
  height: 116px;
  background-image: url(../img/main-menu/img-tree-left.svg);
}
@media screen and (max-width: 770px) {
  .menu-special > li:nth-child(even)::after {
    bottom: 0;
    left: 5px;
    width: 71.5px;
    height: 58px;
  }
}

.menu-normal > li:nth-child(4n + 1)::before {
  bottom: 0;
  left: 40px;
  width: 166px;
  height: 98px;
  background-image: url(../img/main-menu/img-normal-01.svg);
}
@media screen and (max-width: 770px) {
  .menu-normal > li:nth-child(4n + 1)::before {
    bottom: -10px;
    left: 5px;
    width: 83px;
    height: 49px;
  }
}
.menu-normal > li:nth-child(4n + 1)::after {
  right: 30px;
  bottom: 10px;
  width: 143px;
  height: 116px;
  background-image: url(../img/main-menu/img-tree-right.svg);
}
@media screen and (max-width: 770px) {
  .menu-normal > li:nth-child(4n + 1)::after {
    right: 5px;
    bottom: 0;
    width: 71.5px;
    height: 58px;
  }
}
.menu-normal > li:nth-child(4n + 2)::before {
  right: 20px;
  bottom: 10px;
  width: 200px;
  height: 96px;
  background-image: url(../img/main-menu/img-normal-02.svg);
}
@media screen and (max-width: 770px) {
  .menu-normal > li:nth-child(4n + 2)::before {
    right: 0;
    bottom: 0;
    width: 100px;
    height: 48px;
  }
}
.menu-normal > li:nth-child(4n + 2)::after {
  bottom: 10px;
  left: 30px;
  width: 143px;
  height: 116px;
  background-image: url(../img/main-menu/img-tree-left.svg);
}
@media screen and (max-width: 770px) {
  .menu-normal > li:nth-child(4n + 2)::after {
    bottom: 0;
    left: 5px;
    width: 71.5px;
    height: 58px;
  }
}
.menu-normal > li:nth-child(4n + 3)::before {
  bottom: 0;
  left: 5px;
  width: 198px;
  height: 114px;
  background-image: url(../img/main-menu/img-normal-03.svg);
}
@media screen and (max-width: 770px) {
  .menu-normal > li:nth-child(4n + 3)::before {
    bottom: -20px;
    left: 0;
    width: 99px;
    height: 57px;
  }
}
.menu-normal > li:nth-child(4n + 3)::after {
  right: 30px;
  bottom: 10px;
  width: 143px;
  height: 116px;
  background-image: url(../img/main-menu/img-tree-right.svg);
}
@media screen and (max-width: 770px) {
  .menu-normal > li:nth-child(4n + 3)::after {
    right: 5px;
    bottom: 0;
    width: 71.5px;
    height: 58px;
  }
}
.menu-normal > li:nth-child(4n + 4)::before {
  right: 30px;
  bottom: 10px;
  width: 166px;
  height: 106px;
  background-image: url(../img/main-menu/img-normal-04.svg);
}
@media screen and (max-width: 770px) {
  .menu-normal > li:nth-child(4n + 4)::before {
    right: 5px;
    bottom: -5px;
    width: 83px;
    height: 53px;
  }
}
.menu-normal > li:nth-child(4n + 4)::after {
  bottom: 10px;
  left: 30px;
  width: 143px;
  height: 116px;
  background-image: url(../img/main-menu/img-tree-left.svg);
}
@media screen and (max-width: 770px) {
  .menu-normal > li:nth-child(4n + 4)::after {
    bottom: 0;
    left: 5px;
    width: 71.5px;
    height: 58px;
  }
}
*/

::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}
::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 0.5em;
  border: 0.25em solid #fff;
}
::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 1em;
  border: 0.25em solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background: #444;
}
</style>
