<template>
  <Modal class="dialog-twitter" @click="close">
    <div class="dialog-wrapper">
      <div ref="twitter_container" class="twitter_container">
        <a
          class="twitter-timeline"
          :href="'https://twitter.com/' + twitter_id + '?ref_src=twsrc%5Etfw'"
        >
          Tweets by えひめっこ情報リテラシーアプリ
        </a>
      </div>
      <div class="dialog-options">
        <ul>
          <li
            v-for="(option, id) in options"
            :key="option + id"
            :class="['dialog-option', option.style]"
            @click="select(option)"
          >
            <span v-html="option.text"></span>
          </li>
        </ul>
      </div>
    </div>
  </Modal>
</template>

<script>
import modal from '../mixins/modal';
export default {
  name: 'DialogTwitter',
  components: {
    Modal: () => import('./Modal.vue')
  },
  mixins: [modal],
  props: {
    twitter_id: { type: String, required: true },
    options: { type: Array, default: () => [{ text: '閉じる', value: true }] },
    onClose: { type: Function, required: true }
  },
  data() {
    return {
      visible: false,
      result: false
    };
  },
  mounted() {
    // eslint-disable-next-line no-undef
    twttr.widgets.load(this.$refs.twitter_container);
  },
  beforeUpdate() {
    // eslint-disable-next-line no-undef
    twttr.widgets.load(this.$refs.twitter_container);
  },
  methods: {
    select(option) {
      this.onClose(option.value);
      this.close();
    }
  }
};
</script>

<style scoped>
.dialog-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  min-width: 80vw;
}

@media screen and (min-aspect-ratio: 1/1) {
  .dialog-wrapper {
    min-width: 50vw;
  }
}

.twitter_container {
  overflow: auto;
}

.dialog-options ul {
  display: flex;
  margin: 0;
  color: #fff;
  user-select: none;
  flex-direction: column;
}

.dialog-option span {
  font-size: 150%;
}
.dialog-option {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  background-color: #fca249;
}

.dialog-option:hover {
  font-weight: bold;
  opacity: 0.8;
}

.dialog-option:active {
  box-shadow: inset 0.1em 0.1em 0.3em #0004;
  opacity: 0.6;
}

.dialog-option.highlight {
  background-color: #db4807;
}

.dialog-option:not(:first-child) {
  margin-top: 1px;
}

.dialog-option:last-child {
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}
</style>
