export const LITERACY_MAP_HELP = [
  [
    '最初は、ステージ１が選べます。まず、ステージ１を選択してください。',
    'ステージ１の検定をクリアすると、ステージ２へ進むことができます。'
  ],
  [
    'ステージ１を選択すると、部屋が２つあります。',
    '最初は、各部屋の体験ゾーンを選びましょう。',
    '',
    '体験ゾーンをクリアすると、対応ゾーン、検定ゾーンの順に選べるようになります。',
    '検定に合格すると、その部屋はクリアです。'
  ],
  [
    'ステージ内の２つの部屋をクリアすると',
    '新しいステージが選べるようになります。'
  ],
  ['ログアウト（アプリを終了）する場合は', '船のアイコンを選んでください。'],
  [
    'アンケートがある場合、鯛のアイコンが表示されます。',
    'アイコンをクリックするとアンケートが表示されます。',
    '「ぜひ、答えてくださいね。」'
  ],
  ['アンケートの例です。'],
  [
    'ニュースがある場合、こまどりのアイコンが表示されます。',
    'アイコンをクリックするツイッターのニュースが表示されます。',
    '「ぜひ、読んでくださいね。」'
  ],
  ['ニュースの例です。']
].map((arr, i) => {
  return {
    image: require(`@/assets/img/help/${i}.png`),
    message: arr.map(s => '<p>' + (!s ? '<br />' : s) + '</p>').join('')
  };
});

// export const LITERACY_MAP_HELP = [
//   {
//     //画像は高さが画面の高さ半分になるよう自動的にサイズ調整される。
//     //静的画像リソースに対してrequireで読み込み。
//     image: require('@/assets/img/help/1.png'),
//     //フォーマット処理をせずに直接htmlとして解釈する仕様。
//     //したがってrubyが必用な場合はmessageへいれこむこと。
//     //tableタグ等も利用できる(使うならcssは別途定義が必要)。
//     message: hoge[0].map(p).join('')
//   },
